import { isNotNull, isTokenExpire, logOutUser, isFormData } from "src/utils/constants";
import { getBaseURL } from './appConfig';
import { store } from "src/redux/store";
import { showLoader, hideLoader } from '../redux/reducers/loader.reducer';

const fetchRequest = {
    version: '/v1',

    get(api_endpoint) {
        return this.apiResponse('GET', api_endpoint);
    },

    post(api_endpoint, body) {
        return this.apiResponse('POST', api_endpoint, body);
    },

    put(api_endpoint, body) {
        return this.apiResponse('PUT', api_endpoint, body);
    },

    patch(api_endpoint, body) {
        return this.apiResponse('PATCH', api_endpoint, body);
    },

    delete(api_endpoint, body={}) {
        return this.apiResponse('DELETE', api_endpoint, body);
    },

    getAll(api_endpoints) {
        return this.allApiResponse('GET', [...api_endpoints]);
    },

    apiResponse(method, api_endpoint, body) {
        let url = getBaseURL() + this.version + api_endpoint;
        let formData = isFormData(body);
        let headers = {};
            
        if(!formData){
            headers = {
                "Content-Type":"application/json"
            }
        }
    
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: method,
                headers: headers,
                credentials: 'include'
            };

            if (body) {
                if(formData) {
                    requestOptions.body = body;
                } else {
                    requestOptions.body = JSON.stringify(body);
                }
            }

            store.dispatch(showLoader()); // Show loader
            fetch(url, requestOptions)
                .then(response => {
                    return response.json();
                })
                .then(responseJson => {
                    // Check for token expiration
                    if (isTokenExpire(responseJson, api_endpoint) && isNotNull(store?.getState()?.authReducer?.data)){
                        logOutUser();
                    }
                    resolve(responseJson);
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    store.dispatch(hideLoader()); // Hide loader in all cases
                });
        });
    },

    allApiResponse(method, api_endpoints) {
        let urls = api_endpoints.map((i) => getBaseURL() + this.version + i);
    
        let headers = {
            "Content-Type": "application/json"
        };
    
        const requestOptions = {
            method: method,
            headers: headers,
            credentials: 'include'
        };
    
        // Wrap the whole thing in a new Promise
        return new Promise((resolve, reject) => {
            // Use Promise.all to handle multiple fetch requests
            Promise.all(
                urls.map((url) => fetch(url, requestOptions))
            )
            .then((responses) => {
                // Wait for all responses to be converted to JSON
                return Promise.all(responses.map((res) => res.json()));
            })
            .then((data) => {
                data.forEach((response) => {
                    // Check for token expiration
                    if (isTokenExpire(response, getBaseURL()) && isNotNull(store?.getState()?.authReducer?.data)){
                        logOutUser();
                    }
                });
                
                // Resolve the data
                resolve(data);
            })
            .catch((error) => {
                // Reject the promise if any fetch fails
                reject(error);
            });
        });
    }
    
};

export default fetchRequest;