import fetchRequest from "src/network";
import { authActionLogout } from "src/redux/reducers/auth.reducer";
import { store } from "src/redux/store";
// import { swalError } from "./sweetPopup";
import { BookingStatus, NameTitle, UserGender } from "./localData";
import routes from "src/routes";
import moment from "moment";
import { swalError } from "./sweetPopup";
import AppColors from "./appColors";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const isNotNull = (val) => (val !== null && val !== undefined && val !== '');

const isNotZero = (num) => (num !== 0 && num !== "");

const errorMsg = {
  tokenExpireMsg: {
    message: "Error!",
    error_message: "User session expired or invalid user token"
  },
  forbiddenError: {
    message: "Error!",
    error_message: "Access Forbidden: Not Authorized"
  }
};

const isTokenExpire = (res, endPoint) => {
  if (res?.status_code === 401) {
    if (!endPoint?.includes("/auth/logout")) {
      // swalError(res);
    }
    return true;
  } else if (res?.status_code === 403) {
    if (!endPoint?.includes("/auth/logout")) {
      swalError(errorMsg?.forbiddenError);
    }
    return false;
  } else {
    return false;
  }
};

const logOutUser = () => {
  store.dispatch(authActionLogout());
  fetchRequest.get('/auth/logout')
    .then((response) => {
      console.log("RESPONSE", response);
    }).catch((error) => {
      console.log("ERROR", error);
    });
};

const checkSession = () => {
  fetchRequest.get('/auth/session')
    .then((response) => {
      console.log("RESPONSE", response);
      // store.dispatch(authActionLogout());
      // if (response?.status_code === 200){
      // }
    }).catch((error) => {
      console.log("ERROR", error);
    });
}

const isFormData = (data) => {
  return data instanceof FormData;
}

const getTitleKey = (title) => NameTitle?.filter((i) => i?.value === title ? i?.key : "")?.[0]?.key ?? "";

const getGenderKey = (gender) => UserGender?.filter((i) => i?.value === gender ? i?.key : "")?.[0]?.key ?? "";

const openInNewTab = (url) => {
  window.open(url, '_blank');
}

const removeBracketId = (input) => {
  return input.replace(/\s*\(\d+\)/, '');  // \(\d+\) matches the number in parentheses
}

const getBookingStatusString = (status) => {
  return BookingStatus?.filter(i => i?.value === status)?.[0]?.key;
}

const removeLastTwoZeros = (str) => {
  return str.replace(/:\d{2}$/, "");
}

const flotToTime = (flot) => {
  const hours = Math.floor(flot);
  const minutes = Math.round((flot - hours) * 60); // Get the decimal part, convert to minutes
  return `${hours}h ${minutes}m`;
}

const getLocState = (loc) => {
  let locState = store?.getState()?.locStateReducer?.data ?? [];
  let result = locState?.filter((i) => i?.id === loc && i)?.[0]?.name;
  if (isNotNull(result)) {
    return result;
  }
  return loc?.toString()?.toUpperCase();
}

const getRouteRow = (pathName) => {
  return routes?.filter((i) => i?.path === pathName)?.[0]
}

function numberToWords(num) {
  if (num === 0) return 'zero';

  const belowTwenty = [
    'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];
  const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];
  const thousands = ['', 'thousand', 'million', 'billion'];

  function convertHundred(num) {
    let result = '';
    if (num >= 100) {
      result += belowTwenty[Math.floor(num / 100)] + ' hundred ';
      num %= 100;
    }
    if (num >= 20) {
      result += tens[Math.floor(num / 10)] + ' ';
      num %= 10;
    }
    if (num > 0) {
      result += belowTwenty[num] + ' ';
    }
    return result.trim();
  }

  function convertToWords(num) {
    let result = '';
    let thousandCounter = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        result = convertHundred(num % 1000) + ' ' + thousands[thousandCounter] + ' ' + result;
      }
      num = Math.floor(num / 1000);
      thousandCounter++;
    }

    return result.trim();
  }

  return convertToWords(num);
}

function convertFloatToWords(num) {
  const [integerPart, decimalPart] = num.toString().split('.');
  const integerWords = numberToWords(parseInt(integerPart));
  const decimalWords = decimalPart ? numberToWords(parseInt(decimalPart)) : '';
  return decimalPart ? `${integerWords} rupees and ${decimalWords} paisa only` : `${integerWords} rupees only`;
}

function convertDateFormat(dateString) {
  if (isNotNull(dateString)) {
    return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
  } else {
    return "-";
  }
}

const getUser = (obj) => obj?.user;

const getValueByKey = (arr, value) => arr?.filter((i) => i?.value === value ? i?.key : "")?.[0]?.key ?? "";

const getDateTimeDiff = (bookingEndDate, bookingEndTime, bookingStartDate, bookingStartTime) => {
  const endDate = moment(`${bookingEndDate} ${bookingEndTime}`, 'YYYY-MM-DD HH:mm');
  const startDate = moment(`${bookingStartDate} ${bookingStartTime}`, 'YYYY-MM-DD HH:mm');

  const totalMinutes = endDate.diff(startDate, 'minutes');

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${isNaN(hours) ? 0 : hours} hours and ${isNaN(minutes) ? 0 : minutes} minutes`;
}

const getTaxableOrNonTaxable = (data) => {
  let taxable = [];
  let nonTaxable = [];

  if (isNotNull(data)) {
    const keyNames = {
      state_tax_gst: { value: "State Tax", field: "state_tax_charges" },
      mcd_charges_gst: { value: "MCD Charges", field: "mcd_charges" },
      toll_charges_gst: { value: "Toll Charges", field: "toll_charges" },
      fastag_charges_gst: { value: "Fastag Charges", field: "fastag_charges" },
      parking_charges_gst: { value: "Parking Charges", field: "parking_charges" }
    };

    // Iterate through the object
    for (const [key, value] of Object.entries(data)) {
      // Skip keys containing "amount"
      if (key.includes("amount")) {
        continue;
      }

      const readableName = keyNames[key] || key; // Fallback to key if no mapping exists
      if (value) {
        taxable.push({ key: readableName, value });
      } else {
        nonTaxable.push({ key: readableName, value });
      }
    }
  }
  return {
    taxable: taxable,
    nonTaxable: nonTaxable,
  }
}

const mapApiPermissions = (apiPermissions, mapping) => {
  const permissions = {};

  Object.entries(mapping).forEach(([logicalKey, apiKey]) => {
    // Extract the category and actual permission key
    const [category, permission] = apiKey.split(".");

    // Check if the API response contains the category and permission
    permissions[logicalKey] = apiPermissions[category]?.includes(permission) || false;
  });

  return permissions;
};

const ratingColor = (rating) => {
  switch (rating) {
    case 1:
      return AppColors?.red;
    case 2:
      return AppColors?.orange;
    case 3:
      return AppColors?.yellow;
    case 4:
      return AppColors?.lightGreen;
    case 5:
      return AppColors?.green;
    default:
      return "transparent";
  }
}

const bookingPriorityColor = (priority) => {
  switch (priority) {
    case 'FEMALE':
      return AppColors?.lightPink;
    case 'VVIP':
      return AppColors?.greenYellow;
    case 'VIP':
      return AppColors?.lightGreenYellow;
    case 'LUGGAGE_CAR':
      return AppColors?.lightYellow;
    case 'CARRIER_CAR':
      return AppColors?.lightBlue;

    default:
      return '';
  }
}

const removeHash = (value) => {
  if (typeof (value) === "string") {
    if (value?.startsWith("#")) {
      return value?.slice(1);
    } else {
      return value;
    }
  } else {
    return value;
  }
}

export {
  emailRegex,
  isNotNull,
  isNotZero,
  logOutUser,
  errorMsg,
  isTokenExpire,
  checkSession,
  isFormData,
  getTitleKey,
  getGenderKey,
  openInNewTab,
  removeBracketId,
  getBookingStatusString,
  flotToTime,
  getLocState,
  getRouteRow,
  numberToWords,
  getUser,
  getValueByKey,
  getDateTimeDiff,
  convertFloatToWords,
  convertDateFormat,
  getTaxableOrNonTaxable,
  mapApiPermissions,
  ratingColor,
  removeLastTwoZeros,
  bookingPriorityColor,
  removeHash
};