const TripType = [
    {
        key: "Select trip type",
        value: ""
    },
    {
        key: "Local",
        value: "LOCAL"
    },
    {
        key: "Outstation",
        value: "OUTSTATION"
    },
];

const ChargeType = [
    {
        key: "Select charge type",
        value: ""
    },
    {
        key: "Point to Point",
        value: "POINT_TO_POINT"
    },
    {
        key: "Garage to Garage",
        value: "GARAGE_TO_GARAGE"
    },
];

const Packages = [
    {
        key: "Select pakage",
        value: ""
    },
    {
        key: "4Hr 40Km",
        value: "4Hr 40Km"
    },
    {
        key: "8Hr 80Km",
        value: "8Hr 80Km"
    },
    {
        key: "Airport Transfer",
        value: "Airport Transfer"
    }
];

const PackageTypes = [
    {
        key: "Select trip type",
        value: ""
    },
    {
        key: "Local",
        value: "LOCAL"
    },
    {
        key: "Outstation",
        value: "OUTSTATION"
    },
];

const TollCharges = [
    {
        key: "Select toll charges",
        value: ""
    },
    {
        key: "On Actuals",
        value: "ON_ACTUALS"
    },
    {
        key: "On Actuals With GST",
        value: "ON_ACTUALS_WITH_GST"
    },
    {
        key: "None",
        value: "NONE"
    },
];

const ParkingCharges = [
    {
        key: "Select parking charges",
        value: ""
    },
    {
        key: "On Actuals",
        value: "ON_ACTUALS"
    },
    {
        key: "On Actuals With GST",
        value: "ON_ACTUALS_WITH_GST"
    },
    {
        key: "None",
        value: "NONE"
    },
];

const MCDCharges = [
    {
        key: "Select parking charges",
        value: ""
    },
    {
        key: "On Actuals",
        value: "ON_ACTUALS"
    },
    {
        key: "On Actuals With GST",
        value: "ON_ACTUALS_WITH_GST"
    },
    {
        key: "None",
        value: "NONE"
    },
];

const FasTagCharges = [
    {
        key: "Select parking charges",
        value: ""
    },
    {
        key: "On Actuals",
        value: "ON_ACTUALS"
    },
    {
        key: "On Actuals With GST",
        value: "ON_ACTUALS_WITH_GST"
    },
    {
        key: "None",
        value: "NONE"
    },
];

const StateTexCharges = [
    {
        key: "Select parking charges",
        value: ""
    },
    {
        key: "On Actuals",
        value: "ON_ACTUALS"
    },
    {
        key: "On Actuals With GST",
        value: "ON_ACTUALS_WITH_GST"
    },
    {
        key: "None",
        value: "NONE"
    },
];

const FuelType = [
    {
        key: "Select Fule Type",
        value: ""
    },
    {
        key: "Diesel",
        value: "DIESEL"
    },
    {
        key: "Petrol",
        value: "PETROL"
    },
    {
        key: "CNG",
        value: "CNG"
    },
    {
        key: "Electric",
        value: "ELECTRIC"
    },
];

const TaxOnParking = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Required",
        value: "REQUIRED"
    },
    {
        key: "Not required",
        value: "NOT_REQUIRED"
    }
];

const CreditCardChargesStatus = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Required",
        value: "REQUIRED"
    },
    {
        key: "Not required",
        value: "NOT_REQUIRED"
    }
];

const GSTPercentages = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "0%",
        value: "0.00"
    },
    {
        key: "5%",
        value: "5.00"
    },
    {
        key: "12%",
        value: "12.00"
    },
    {
        key: "18%",
        value: "18.00"
    },
    {
        key: "28%",
        value: "28.00"
    },
];

const WeekDays = [
    {
        label: "MONDAY",
        value: "MONDAY"
    },
    {
        label: "TUESDAY",
        value: "TUESDAY"
    },
    {
        label: "WEDNESDAY",
        value: "WEDNESDAY"
    },
    {
        label: "THURSDAY",
        value: "THURSDAY"
    },
    {
        label: "FRIDAY",
        value: "FRIDAY"
    },
    {
        label: "SATURDAY",
        value: "SATURDAY"
    },
    {
        label: "SUNDAY",
        value: "SUNDAY"
    }
];

const NumbersKeyValue = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Charge Extra Hours Only",
        value: "-2"
    },
    {
        key: "Charge Actual + Extra Hours",
        value: "-1"
    },
    {
        key: "PRO RATA + Extra Hours",
        value: "0"
    },
    {
        key: "1",
        value: "1"
    },
    {
        key: "2",
        value: "2"
    },
    {
        key: "3",
        value: "3"
    },
    {
        key: "4",
        value: "4"
    },
    {
        key: "5",
        value: "5"
    },
    {
        key: "6",
        value: "6"
    },
    {
        key: "7",
        value: "7"
    },
    {
        key: "8",
        value: "8"
    },
    {
        key: "9",
        value: "9"
    },
    {
        key: "10",
        value: "10"
    },
    {
        key: "11",
        value: "11"
    },
    {
        key: "12",
        value: "12"
    },
    {
        key: "13",
        value: "13"
    },
    {
        key: "14",
        value: "14"
    },
    {
        key: "15",
        value: "15"
    },
    {
        key: "16",
        value: "16"
    },
    {
        key: "17",
        value: "17"
    },
    {
        key: "18",
        value: "18"
    },
    {
        key: "19",
        value: "19"
    },
    {
        key: "20",
        value: "20"
    },
    {
        key: "21",
        value: "21"
    },
    {
        key: "22",
        value: "22"
    },
    {
        key: "23",
        value: "23"
    },
    {
        key: "24",
        value: "24"
    },
];

const SourceOfBooking = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Email",
        value: "EMAIL"
    },
    {
        key: "SMS",
        value: "SMS"
    },
    {
        key: "Call",
        value: "CALL"
    },
    {
        key: "Whatsapp",
        value: "WHATSAPP"
    },
    {
        key: "Web",
        value: "WEB"
    }
];

const BookingType = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Corporate",
        value: "CORPORATE"
    },
    {
        key: "Individual",
        value: "INDIVIDUAL"
    }
];

const PickedFrom = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Airport",
        value: "AIRPORT"
    },
    {
        key: "Railway Station",
        value: "RAILWAY_STATION"
    },
    {
        key: "Bus Stand",
        value: "BUS_STAND"
    },
    {
        key: "Hotel",
        value: "HOTEL"
    },
    {
        key: "Office",
        value: "OFFICE"
    },
    {
        key: "Home",
        value: "HOME"
    }
];

const GuestTravelMode = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "By Air",
        value: "AIR"
    },
    {
        key: "By Rail",
        value: "RAIL"
    },
    {
        key: "By Bus",
        value: "BUS"
    },
];

const BookingStatus = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "PENDING",
        value: "PENDING"
    },
    {
        key: "CONFIRMED",
        value: "CONFIRMED"
    },
    {
        key: "CANCELLED",
        value: "CANCELLED"
    },
    {
        key: "DISPATCHED",
        value: "DISPATCHED"
    },
    {
        key: "CLOSED",
        value: "CLOSED"
    },
    {
        key: "NOT REQUIRED",
        value: "NOT_REQUIRED"
    }
];

const NameTitle = [
    {
        key: "Title",
        value: ""
    },
    {
        key: "Mr.",
        value: "MR"
    },
    {
        key: "Mrs.",
        value: "MRS"
    },
    {
        key: "Ms.",
        value: "MS"
    },
    {
        key: "Dr.",
        value: "DR"
    },
    {
        key: "Prof.",
        value: "PROF"
    }
];

const UserGender = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Male",
        value: "MALE"
    },
    {
        key: "Female",
        value: "FEMALE"
    },
];

const PackageType = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Long Term Rental",
        value: "LTR"
    },
    {
        key: "Short Term Rental",
        value: "STR"
    },
];

const DayStatus = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Working Day",
        value: "WORKING"
    },
    {
        key: "Holiday",
        value: "HOLIDAY"
    },
];

const BoolType = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Yes",
        value: true
    },
    {
        key: "No",
        value: false
    },
];

const InternalUsersType = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Superadmin",
        value: "superadmin"
    },
    {
        key: "Admin",
        value: "admin"
    },
    {
        key: "Booking Desk",
        value: "booking_desk"
    },
    {
        key: "Billing Desk",
        value: "billing_desk"
    },
    {
        key: "Executive",
        value: "executive"
    },
    {
        key: "User",
        value: "user"
    },
    {
        key: "Vendor",
        value: "vendor"
    },
    {
        key: "Customer",
        value: "customer"
    },
    {
        key: "Driver",
        value: "driver"
    }
];

const BookingPeriorityType = [
    {
        key: "Select an option",
        value: ""
    },
    {
        key: "Female",
        value: "FEMALE"
    },
    {
        key: "VVIP",
        value: "VVIP"
    },
    {
        key: "VIP",
        value: "VIP"
    },
    {
        key: "Luggage Car",
        value: "LUGGAGE_CAR"
    },
    {
        key: "Carrier Car",
        value: "CARRIER_CAR"
    },
];

const BookingSortByType = [
    {
        key: "Sort By",
        value: ""
    },
    {
        key: "Datetime ascending",
        value: "sort_by=booking_datetime&order=asc"
    },
    {
        key: "Datetime descending",
        value: "sort_by=booking_datetime&order=desc"
    }
]

export {
    TripType,
    ChargeType,
    Packages,
    PackageTypes,
    TollCharges,
    ParkingCharges,
    FuelType,
    TaxOnParking,
    CreditCardChargesStatus,
    MCDCharges,
    FasTagCharges,
    StateTexCharges,
    GSTPercentages,
    WeekDays,
    NumbersKeyValue,
    SourceOfBooking,
    BookingType,
    PickedFrom,
    GuestTravelMode,
    BookingStatus,
    NameTitle,
    UserGender,
    PackageType,
    DayStatus,
    BoolType,
    InternalUsersType,
    BookingPeriorityType,
    BookingSortByType
};