import React, { Suspense } from "react";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import withBookingRulesAndState from "src/utils/hocs/withBookingRulesAndState";

// Containers
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('../views/pages/login/Login'));
const SendOTP = React.lazy(() => import('../views/pages/forgotPassword/SendOTP'));
const ForgotPassword = React.lazy(() => import('../views/pages/forgotPassword/ForgotPassword'));
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('../views/pages/page500/Page500'));
const InvoiceGenerator = React.lazy(() => import('../views/invoiceMaster/InvoiceGenerator'));
const DutySlipGenerator = React.lazy(() => import('../views/bookings/DutySlipGenerator'));

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const AuthRoute = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/forgot-password" element={<SendOTP />} />
                    <Route exact path="/reset-password" element={<ForgotPassword />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
};

const AppRoute = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route exact path="/404" element={<Page404 />} />
                    <Route exact path="/500" element={<Page500 />} />
                    <Route exact path="/invoices/download/:id" element={<InvoiceGenerator />} />
                    <Route exact path="/duty-slip/download/:id" element={<DutySlipGenerator />} />
                    <Route path="*" element={<DefaultLayout />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
};

export {
    AuthRoute
};
export default withBookingRulesAndState(AppRoute);