const AppColors = {
    green: "#57E32C",
    lightGreen: "#B7DD29",
    greenYellow: '#D1FFBD',
    lightGreenYellow: '#f0ffe1',
    yellow: "#FFE234",
    lightYellow: '#FFFFC5',
    orange: "#FFA534",
    red: "#FF4545",
    lightPink: "#FFE6EE",
    lightBlue: "#D1EEFF"
};

export default AppColors;